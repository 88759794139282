import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { useMediaQuery } from "react-responsive"

// Custom styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Carousel from "react-bootstrap/Carousel"
import styles from "./about-us.module.scss"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import FooterCTA from "../components/FooterCTA"
import BannerShape from "../components/BannerShape"

// Content
import {
  seo_title,
  seo_description,
  banner_text,
  logo_containers,
  get_to_know,
  get_to_know_content,
} from "../../content/about.json"

// react-responsive (easy-mode)
const MaxWidth575 = ({ children }) => {
  const isMax575px = useMediaQuery({ maxWidth: 575 })
  return isMax575px ? children : null
}

const MinWidth576 = ({ children }) => {
  const isMin576px = useMediaQuery({ minWidth: 576 })
  return isMin576px ? children : null
}

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={seo_title}
        description={seo_description}
        image={data.seoBanner.childImageSharp.resize}
      />
      <Container
        fluid
        className={styles.container}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.bannerBackgroundImage.publicURL});
          }
        `}
      >
        <MaxWidth575>
          <BannerShape />
          <Container>
            <Row>
              <Col className={styles.imgCol}>
                <Image
                  className={styles.bannerImg}
                  src={data.bannerImg.publicURL}
                  alt="Worry-free about your bookkeeping"
                  fluid
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h1>
                  We{" "}
                  <span>
                    <Img
                      fluid={data.heartImage.childImageSharp.fluid}
                      objectFit="cover"
                      alt="LOVE"
                      className={styles.heartSpan}
                    />
                  </span>{" "}
                  making it simple for you
                </h1>
              </Col>
            </Row>
          </Container>
        </MaxWidth575>
        <MinWidth576>
          <Container>
            <Row>
              <Col>
                <h1>
                  We{" "}
                  <span>
                    <Img
                      fluid={data.heartImage.childImageSharp.fluid}
                      objectFit="cover"
                      alt="LOVE"
                      className={styles.heartSpan}
                    />
                  </span>{" "}
                  making it simple for you
                </h1>
              </Col>
            </Row>
            <Row className={styles.imageRow}>
              <Col xs={7} className={styles.imgCol}>
                <Image
                  className={styles.bannerImg}
                  src={data.bannerImg.publicURL}
                  alt="Worry-free about your bookkeeping"
                  fluid
                />
              </Col>
              <Col>
                <p
                  className={styles.subHeader}
                  dangerouslySetInnerHTML={{ __html: banner_text }}
                />
              </Col>
            </Row>
          </Container>
        </MinWidth576>
      </Container>
      <Container
        fluid
        className={styles.bodyContainer}
        css={css`
          background-image: url(${data.bodySectionWaveOne.publicURL});
        `}
      >
        <Container>
          <Row>
            <MaxWidth575>
              <Col xs="auto">
                <h3>{get_to_know}</h3>
                <Image
                  src={data.getToKnowUsImg.publicURL}
                  className={styles.getToKnowImg}
                  alt="Get to know us"
                  fluid
                />
              </Col>
              <Col className="my-auto">
                {get_to_know_content.map((item, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: item.txt }} />
                ))}
              </Col>
            </MaxWidth575>
            <MinWidth576>
              <Col className="my-auto">
                <h3>{get_to_know}</h3>
                {get_to_know_content.map((item, i) => (
                  <p key={i} dangerouslySetInnerHTML={{ __html: item.txt }} />
                ))}
              </Col>
              <Col sm={8}>
                <Image
                  src={data.getToKnowUsImg.publicURL}
                  className={styles.getToKnowImg}
                  alt="Get to know us"
                  fluid
                />
              </Col>
            </MinWidth576>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        className={styles.bodyContainer2}
        css={css`
          background-image: url(${data.bodySectionWaveTwo.publicURL});
        `}
      >
        <Container>
          <Row noGutters>
            <Col sm={5}>
              <Image
                src={data.affiliatesImg.publicURL}
                className={styles.affiliatesImg}
                alt="Get to know us"
                fluid
              />
            </Col>
            <Col sm={7}>
              <MaxWidth575>
                <h3 className={styles.credentialTitle}>Our Credentials</h3>
                <Carousel
                  controls={false}
                  css={css`
                    margin-bottom: 8rem;
                    .carousel-indicators {
                      bottom: -3rem;
                      li {
                        background-color: #316546;
                        border-radius: 3px;
                        width: 6px;
                        height: 6px;
                        border-top: unset;
                        border-bottom: unset;
                        margin-left: 8px;
                        margin-right: 8px;
                      }
                    }
                  `}
                >
                  {logo_containers.map((item, i) => (
                    <Carousel.Item className={styles.carouselItem} key={i}>
                      <div className={styles.cardSlideContainer}>
                        <Img
                          fluid={data[item.logo_src].childImageSharp.fluid}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          className={styles.logoImg}
                          alt={item.logo_alt}
                        />
                        <a
                          href={item.icon_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.logo_label}
                        </a>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </MaxWidth575>
              <MinWidth576>
                <div className={styles.logoContainer}>
                  {logo_containers.map((item, i) => (
                    <div className={styles[item.container_class]} key={i}>
                      <a
                        href={item.icon_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Img
                          fluid={data[item.logo_src].childImageSharp.fluid}
                          objectFit="cover"
                          objectPosition="50% 50%"
                          className={styles.logoImg}
                          alt={item.logo_alt}
                        />
                        <p>{item.logo_label}</p>
                      </a>
                    </div>
                  ))}
                </div>
              </MinWidth576>
            </Col>
          </Row>
        </Container>
      </Container>
      <FooterCTA />
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    seoBanner: file(relativePath: { eq: "banner/seo-about-us.png" }) {
      publicURL
      childImageSharp {
        resize(width: 1024) {
          src
          height
          width
        }
      }
    }

    bannerBackgroundImage: file(
      relativePath: { eq: "banner/background-2.svg" }
    ) {
      publicURL
    }

    heartImage: file(relativePath: { eq: "home/heart.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bannerImg: file(relativePath: { eq: "about/header-img.svg" }) {
      publicURL
    }

    bodySectionWaveOne: file(relativePath: { eq: "about/wave-01.svg" }) {
      publicURL
    }

    bodySectionWaveTwo: file(relativePath: { eq: "about/wave-02.svg" }) {
      publicURL
    }

    getToKnowUsImg: file(relativePath: { eq: "about/image-01.svg" }) {
      publicURL
    }

    affiliatesImg: file(relativePath: { eq: "about/about-us.png" }) {
      publicURL
    }

    xeroLogo: file(relativePath: { eq: "about/icon-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    icbLogo: file(relativePath: { eq: "about/icon-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    picpaLogo: file(relativePath: { eq: "about/icon-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    aatLogo: file(relativePath: { eq: "about/icon-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 140, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
